<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/stores"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.storeTicketAdd.title') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ODNTicketForm
        :store-id="parseInt($route.params.id)"
        @saved="onSaved"
        @cancel="onCancel"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
} from '@ionic/vue';

import ODNTicketForm from '@c/odn-ticket-form';

export default {
  name: 'StoreTicketAdd',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    ODNTicketForm,
  },
  methods: {
    async onSaved(ticketId) {
      this.$router.replace(
        `/tabs/stores/${this.$route.params.id}/tickets/${ticketId}`
      );
    },
    onCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
